import isEqual from 'lodash.isequal'
import Image from 'next/image'
import { FC, memo } from 'react'
import { AlertCircleIcon, TrashIcon } from '@components/icons'
import ProductOutOfStockLabel from '@components/product/ProductOutOfStockLabel'
import Link from '@components/ui/Link'
import { useCart } from '@lib/contexts'
import { handleGoogleAnalytics } from '@lib/gtag/gtagEvents'
import { TCartItem } from '@lib/types'
import { getImageSrcFromPath } from '@lib/utils/getImageSrcFromPath'
import { convertToPrice } from '@lib/utils/price/convertToPrice'
import parseProductPrices from '@lib/utils/price/parseProductPrices'
import buildProductUrl from '@lib/utils/product/buildProductUrl'
import { useClientTranslation } from '@app/i18n/client'
import ImageDropShirt from './drop-shirt.png'

interface Props {
  product: TCartItem
}

const CartDropdownItem: FC<Props> = ({ product }) => {
  const { t } = useClientTranslation('product')
  const { removeItemFromCart } = useCart()
  const productId = product.id

  if (!productId) {
    return null
  }

  const { currentPrice } = parseProductPrices({
    currentPrice: product.unitPrice,
    originalPrice: product.originalUnitPrice,
  })

  const imgSrc = getImageSrcFromPath(product.variant.product?.images?.[0]?.path)
  const slug = buildProductUrl(product.variant.product?.slug)

  const handleRemoveFromCart = () => {
    if (product.id) {
      handleGoogleAnalytics('remove_from_cart', {
        productData: [product],
      })
    }

    removeItemFromCart(productId)
  }

  return (
    <div>
      <div className="flex items-start py-3">
        <Link href={slug} className="relative mr-5">
          {ImageDropShirt.src && (
            <Image
              src={imgSrc ?? ImageDropShirt.src}
              alt="product"
              width={70}
              height={77}
              className="bg-accent-5"
            />
          )}
          {!product.variant.inStock && <ProductOutOfStockLabel isCartItem />}
        </Link>
        <div className="flex flex-1 items-start">
          <div className="mr-2 grid grid-flow-row gap-1">
            <Link href={slug}>
              <div className="font-bold">{product.productName}</div>
            </Link>
            <div>
              {t('Size')}:{' '}
              <span className="font-medium">{product.variant.name}</span>
            </div>
            {!!currentPrice && (
              <div>
                {`${product.quantity} x `}
                <span className="text-md font-medium">
                  {convertToPrice(currentPrice)}
                </span>
              </div>
            )}
          </div>
          <button onClick={handleRemoveFromCart}>
            <TrashIcon />
          </button>
        </div>
      </div>
      {!product.variant.inStock && (
        <div
          className={
            'flex items-center rounded bg-warning px-3 py-[10px] text-white'
          }
        >
          <AlertCircleIcon className="mr-2" />
          <span className="font-medium leading-none">
            {t('Sorry, this item is no longer available')}
          </span>
        </div>
      )}
    </div>
  )
}
const areEqual = (prevProps: Props, nextProps: Props) =>
  isEqual(prevProps.product, nextProps.product)

export default memo(CartDropdownItem, areEqual)
