import Image from 'next/image'
import { FC } from 'react'
import Link from '@components/ui/Link'
import { MenuItemImage } from '@lib/types'

interface SubHeaderImageProps {
  image: MenuItemImage
}

const SubHeaderImage: FC<SubHeaderImageProps> = ({ image }) => {
  if (!image?.absoluteUrl) {
    return null
  }

  if (!image?.url) {
    return (
      <div className="relative h-full w-1/2">
        <Image
          src={image.absoluteUrl}
          alt="Banner image"
          className="h-full w-auto object-cover"
          width={266}
          height={283}
        />
      </div>
    )
  }

  return (
    <Link className="relative h-full w-1/2" href={image.url}>
      <Image
        src={image.absoluteUrl}
        alt="Banner image"
        className="h-full w-auto object-cover"
        width={266}
        height={283}
      />
    </Link>
  )
}

export default SubHeaderImage
