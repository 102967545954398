import cn from 'clsx'
import { FC } from 'react'
import SubHeaderImage from '@components/header/SubHeader/SubHeaderContent/SubHeaderImage'
import Link from '@components/ui/Link'
import { MENU_BANNERS_CATEGORY_LABEL } from '@lib/constants/menu'
import { MenuItem } from '@lib/types'

type TSubHeaderContentProps = {
  activeSubCategory: MenuItem
}

const CATEGORIES_NAME = 'Kategorijos'
const MAXIMUM_MENU_ITEMS = 18

const SubHeaderContent: FC<TSubHeaderContentProps> = ({
  activeSubCategory,
}) => {
  return (
    <section
      data-testid="subHeaderContent"
      className={cn(
        'absolute left-0 top-[118px] z-30 hidden w-full border-b border-t bg-white shadow-default group-hover:block'
      )}
    >
      <div className="container my-6 flex">
        <nav className="mr-6 flex w-1/2 text-sm">
          {activeSubCategory?.children?.map((category) => {
            const isCategories = category.label === CATEGORIES_NAME

            return (
              <div
                key={category.id}
                className={cn(
                  'flex flex-col',
                  isCategories ? 'w-2/3' : 'w-1/3',
                  category.label === MENU_BANNERS_CATEGORY_LABEL && 'hidden'
                )}
              >
                <div className="mb-4 font-bold uppercase">{category.label}</div>
                <div
                  className={cn(
                    'grid max-h-[310px] gap-3',
                    isCategories && 'grid-cols-2 '
                  )}
                >
                  {category.children
                    ?.slice(
                      0,
                      isCategories
                        ? MAXIMUM_MENU_ITEMS
                        : Math.floor(MAXIMUM_MENU_ITEMS / 2)
                    )
                    .map((item) => (
                      <div key={item.id} className="hover:text-accent-1">
                        <Link href={item.url ?? '/'}>{item.label}</Link>
                      </div>
                    ))}
                </div>
              </div>
            )
          })}
        </nav>
        {activeSubCategory?.children?.map((item) => {
          if (item?.label !== MENU_BANNERS_CATEGORY_LABEL) {
            return null
          }

          return (
            <div key={`subcategory-${item.id}`} className="flex w-1/2 gap-6">
              {[item.firstImage, item.secondImage]
                .filter(Boolean)
                .map((image, index) => (
                  <SubHeaderImage
                    key={`sub-header-image-${item.id}-${index}`}
                    image={image!}
                  />
                ))}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default SubHeaderContent
