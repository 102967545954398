export default function (
  freeShippingThreshold: number | undefined,
  itemsTotal: number | undefined
) {
  if (
    freeShippingThreshold !== undefined &&
    itemsTotal !== undefined &&
    freeShippingThreshold > 0
  ) {
    return freeShippingThreshold * 100 - itemsTotal
  }

  return undefined
}
