'use client'

import cn from 'clsx'
import lazy from 'next/dynamic'
import { FC, useState } from 'react'
import { CartDropdown } from '@components/cart'
import { Searchbar } from '@components/common'
import NavbarUser from '@components/common/Navbar/NavbarUser'
import SearchButton from '@components/common/Navbar/SearchButton'
import WishlistButton from '@components/common/Navbar/WishlistButton'
import { SubHeader } from '@components/header'
import { HeartIcon } from '@components/icons'
import { Button, Logo } from '@components/ui'
import Link from '@components/ui/Link'
import { useUI } from '@lib/contexts'
import { useLayout } from '@lib/hooks/useLayout'
import { Menu } from '@lib/types'
import NavbarRoot from './NavbarRoot'

const Hamburger = lazy(() => import('@components/common/Navbar/Hamburger'))
interface NavbarProps {
  isCheckout?: boolean
  mainMenuData?: Menu
}

const Navbar: FC<NavbarProps> = ({ isCheckout, mainMenuData }) => {
  const [activeCategory, setActiveCategory] = useState(
    mainMenuData?.children?.[0]
  )

  const { displaySearch } = useUI()
  const { isMobile } = useLayout()

  if (isCheckout) {
    return (
      <NavbarRoot isCheckout>
        <nav className="border-b py-2">
          <div className="container grid auto-cols-max grid-flow-col items-center justify-between gap-6">
            <Link href="/">
              <>
                <Logo />
              </>
            </Link>
            <div>
              <Button width={42} height={42} variant="ghost">
                <HeartIcon />
              </Button>
              <NavbarUser />
            </div>
          </div>
        </nav>
      </NavbarRoot>
    )
  }

  return (
    <NavbarRoot>
      <nav className="relative z-40 border-b bg-white p-2">
        <div
          className={cn(
            'container flex items-center justify-between',
            isMobile && 'pl-0'
          )}
        >
          <Hamburger />
          <Link href="/" className="ml-3 mr-6 lg:ml-0">
            <Logo />
          </Link>
          <div className="flex flex-1 items-center justify-between">
            <div className="mr-6 hidden items-center justify-center text-sm uppercase text-accent-2 lg:flex">
              {mainMenuData?.children?.map((item) => (
                <Link
                  key={item?.id}
                  onClick={() => setActiveCategory(item)}
                  className={cn(
                    'navButton',
                    item.id === activeCategory?.id && 'font-bold text-black'
                  )}
                  href={item?.url ?? ''}
                  title={item?.label ?? ''}
                >
                  {item?.label}
                </Link>
              ))}
            </div>
            <div className="mr-12 hidden w-full lg:flex">
              <Searchbar />
            </div>
          </div>
          <div className="flex">
            <div className="max-lg:hidden">
              <WishlistButton />
            </div>
            <SearchButton />
            <NavbarUser />
            <CartDropdown />
          </div>
        </div>
      </nav>
      {displaySearch && (
        <Searchbar isMobile className="my-4 ml-4 flex lg:hidden" />
      )}
      <SubHeader activeCategory={activeCategory} />
    </NavbarRoot>
  )
}

export default Navbar
