'use client'

import { FC } from 'react'
import FooterLinks from '@components/common/Footer/FooterLinks'
import { FacebookMonoIcon, InstagramIcon } from '@components/icons'
import { Logo } from '@components/ui'
import Link from '@components/ui/Link'
import { useChannel } from '@lib/hooks/common/useChannel'
import { Menu } from '@lib/types'

interface Props {
  menuData: Menu
  lang: string
}

const Footer: FC<Props> = ({ menuData }) => {
  const { data: channel } = useChannel()

  return (
    <>
      <div
        className="border-t"
        id="omnisend-embedded-v2-64999f4f90789f2631ae66c2"
      ></div>
      <footer className="bg-footer py-12 text-white">
        <div className="container flex flex-col justify-between gap-6 lg:flex-row">
          <div className="flex flex-col items-center gap-6 lg:items-start">
            <Logo isWhite />
            <div>
              {channel?.facebookUrl && (
                <Link
                  target="_blank"
                  className="inline-flex h-10 w-10 items-center justify-center hover:text-accent-2"
                  href={channel.facebookUrl}
                >
                  <>
                    <FacebookMonoIcon />
                  </>
                </Link>
              )}
              {channel?.instagramUrl && (
                <Link
                  target="_blank"
                  className="inline-flex h-10 w-10 items-center justify-center hover:text-accent-2"
                  href={channel?.instagramUrl}
                >
                  <>
                    <InstagramIcon />
                  </>
                </Link>
              )}
            </div>
          </div>
          <div>
            <FooterLinks menuData={menuData} />
          </div>
        </div>
        {/*TODO: Enable when EN ready*/}
        {/*<LanguageSwitcher lang={lang} />*/}
      </footer>
    </>
  )
}

export default Footer
