const Trash = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.06783 9.04523H7.38609H17.9322"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6139 9.04521V18.273C16.6139 18.6227 16.475 18.958 16.2278 19.2052C15.9806 19.4524 15.6453 19.5913 15.2956 19.5913H8.70434C8.35471 19.5913 8.01941 19.4524 7.77219 19.2052C7.52497 18.958 7.38608 18.6227 7.38608 18.273V9.04521M9.36347 9.04521V7.72695C9.36347 7.37733 9.50236 7.04202 9.74958 6.7948C9.9968 6.54758 10.3321 6.40869 10.6817 6.40869H13.3183C13.6679 6.40869 14.0032 6.54758 14.2504 6.7948C14.4976 7.04202 14.6365 7.37733 14.6365 7.72695V9.04521"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Trash
