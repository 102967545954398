import cn from 'clsx'
import { FC } from 'react'
import { Img } from '@components/ui'
import { useCart } from '@lib/contexts'
import { useChannel } from '@lib/hooks/common/useChannel'
import { convertToPrice } from '@lib/utils/price/convertToPrice'
import eurLeftToFreeShipping from '@lib/utils/price/eurLeftToFreeShipping'
import { useClientTranslation } from '@app/i18n/client'
import DeliveryIcon from './truck.svg'

const FreeShippingNotification: FC = () => {
  const { t } = useClientTranslation('cart')
  const { cart } = useCart()
  const { data: channel } = useChannel()

  if (channel?.freeShippingThreshold === undefined) {
    return null
  }

  const value = eurLeftToFreeShipping(
    channel?.freeShippingThreshold,
    cart.itemsTotal
  )

  return (
    <div
      className={cn(
        'flex items-center rounded px-3 py-[10px] text-white',
        value && value > 0 ? 'bg-warning' : 'bg-success'
      )}
    >
      <Img width={25} height={24} src={DeliveryIcon.src} alt="Delivery icon" />
      <div className="ml-[10px] text-sm font-medium">
        {value && value > 0
          ? t('Left until free delivery', { value: convertToPrice(value) })
          : t('You are entitled to free delivery.')}
      </div>
    </div>
  )
}
export default FreeShippingNotification
