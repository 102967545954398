import urljoin from 'url-join'
import { staticRoutes } from '@lib/constants/routes'

export default (
  locale: string,
  searchQuery: string | undefined,
  queryParamString?: string
) => {
  return urljoin(
    locale,
    staticRoutes.search.url,
    `?q=${searchQuery ?? ''}`,
    queryParamString || ''
  )
}
