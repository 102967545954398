'use client'

import * as Popover from '@radix-ui/react-popover'
import cn from 'clsx'
import { useParams, usePathname, useRouter } from 'next/navigation'
import { FC, useEffect, useState } from 'react'
import urlJoin from 'url-join'
import CartDropdownContent from '@components/cart/CartDropdownContent'
import { CartIcon } from '@components/icons'
import { Button } from '@components/ui'
import { staticRoutes } from '@lib/constants/routes'
import { useCart } from '@lib/contexts'
import { useLayout } from '@lib/hooks/useLayout'

const CartDropdown: FC = () => {
  const { isDesktop } = useLayout()
  const { cart, productAddToCartState } = useCart()
  const router = useRouter()
  const location = usePathname()
  const params = useParams()
  const lang = params?.lang

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!!productAddToCartState) {
      setIsOpen(true)
    }
  }, [productAddToCartState])

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  const handleClick = () => {
    if (!isDesktop) {
      router.push(urlJoin('/', lang as string, staticRoutes.cart.url))
    }
  }

  const itemCount = cart.items?.length

  const button = (
    <Button
      variant="ghost"
      onClick={handleClick}
      disabled={!itemCount}
      className={cn(
        'relative inline-flex h-11 w-11 items-center justify-center rounded hover:bg-accent-5 focus:outline-0',
        !itemCount && '!bg-transparent'
      )}
      data-testid="mini-cart"
    >
      <CartIcon />
      {!!itemCount && (
        <span className="absolute right-[5px] top-[6px] h-1.5 w-1.5 rounded-full bg-red" />
      )}
    </Button>
  )

  if (isDesktop) {
    return (
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger asChild>{button}</Popover.Trigger>
        <Popover.Content
          align="end"
          className="max-h-[calc(100vh-60px)] w-[320px] overflow-auto shadow"
        >
          <CartDropdownContent />
        </Popover.Content>
      </Popover.Root>
    )
  }

  return button
}

export default CartDropdown
