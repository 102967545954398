'use client'

import { PopoverClose } from '@radix-ui/react-popover'
import { FC, Suspense } from 'react'
import CartDropdownItem from '@components/cart/CartDropdownItem'
import FreeShippingNotification from '@components/cart/CartSummary/FreeShippingNotification/FreeShippingNotification'
import ProductAddedNotification from '@components/cart/ProductAddedNotification'
import { CloseIcon } from '@components/icons'
import Link from '@components/ui/Link'
import { staticRoutes } from '@lib/constants/routes'
import { useCart } from '@lib/contexts'
import { handleGoogleAnalytics } from '@lib/gtag/gtagEvents'
import { convertToPrice } from '@lib/utils/price/convertToPrice'
import { useClientTranslation } from '@app/i18n/client'

const CartDropdownContent: FC = () => {
  const { cart } = useCart()
  const { t } = useClientTranslation('cart')

  return (
    <div className="bg-white text-sm">
      <header className="flex items-center justify-between border-b px-6 py-4 font-bold uppercase">
        <span>
          {t('Product cart')} ({cart.items?.length})
        </span>
        <PopoverClose>
          <CloseIcon />
        </PopoverClose>
      </header>
      <section className="grid max-h-[285px] gap-1 overflow-auto border-b py-3 pl-6 pr-3">
        <Suspense fallback={null}>
          <ProductAddedNotification />
        </Suspense>
        <FreeShippingNotification />
        {cart.items?.map((product, index) => (
          <CartDropdownItem product={product} key={`mini-cart-item-${index}`} />
        ))}
      </section>
      <footer className="grid gap-4 pt-3">
        <div className="flex justify-between px-6 text-md font-bold uppercase">
          <span>{t('Total')}</span>
          <span>{convertToPrice(cart.itemsTotal)}</span>
        </div>
        <div className="mb-4 grid gap-4 px-6">
          <Link
            href={staticRoutes.checkoutDelivery.url}
            className="primaryButton"
            onClick={() =>
              cart.items?.length &&
              handleGoogleAnalytics('begin_checkout', {
                productData: cart.items,
              })
            }
          >
            {t('Buy')}
          </Link>
          <Link href="/cart" className="alternateButton w-full">
            {t('Cart overview')}
          </Link>
        </div>
      </footer>
      {/* NOTE: Client asked to remove this temporarily AR-656 */}
      {/*<DiscountCodeInput />*/}
    </div>
  )
}

export default CartDropdownContent
