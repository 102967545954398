'use client'

import cn from 'clsx'
import { SearchIcon } from '@components/icons'
import { Button } from '@components/ui'
import { useUI } from '@lib/contexts'

const SearchButton = () => {
  const { toggleSearch, displaySearch } = useUI()

  return (
    <Button
      width={44}
      height={44}
      variant="ghost"
      onClick={toggleSearch}
      data-testid="search-toggle"
      className={cn(displaySearch && 'bg-accent-5', 'lg:hidden')}
    >
      <SearchIcon />
    </Button>
  )
}

export default SearchButton
