'use client'

import cn from 'clsx'
import { FC, useEffect } from 'react'
import { AlertCircleIcon, CloseIcon, CheckCircleIcon } from '@components/icons'
import { ENotificationView } from '@lib/constants/notification'
import { useUI } from '@lib/contexts'

interface INotificationProps {
  className?: string
  id: string | string[]
  keepNotificationVisible?: boolean
}

const Notification: FC<INotificationProps> = ({
  className,
  id,
  keepNotificationVisible,
}) => {
  const {
    displayNotification,
    closeNotification,
    notificationView,
    notificationValue,
    notificationId,
  } = useUI()
  const isNotificationActive = notificationId
    ? Array.isArray(id)
      ? id.includes(notificationId)
      : id === notificationId
    : false

  useEffect(() => {
    if (
      !keepNotificationVisible &&
      displayNotification &&
      isNotificationActive
    ) {
      const timer = setTimeout(() => {
        closeNotification()
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [
    closeNotification,
    displayNotification,
    keepNotificationVisible,
    id,
    notificationId,
    isNotificationActive,
  ])

  if (!displayNotification || !isNotificationActive) {
    return null
  }

  let style: string
  let icon: JSX.Element

  switch (notificationView) {
    case ENotificationView.Error:
      style = 'bg-error'
      icon = <AlertCircleIcon />
      break
    case ENotificationView.Warning:
      style = 'bg-warning'
      icon = <AlertCircleIcon />
      break
    default:
      style = 'bg-success'
      icon = <CheckCircleIcon />
  }

  return (
    <div
      className={cn(
        style,
        'flex items-center rounded px-3 py-2.5 text-sm font-medium text-white',
        className
      )}
    >
      {icon}
      <span className="mx-2.5">{notificationValue}</span>
      <button onClick={closeNotification} className="ml-auto">
        <CloseIcon />
      </button>
    </div>
  )
}

export default Notification
