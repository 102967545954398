const Instagram = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0022 6.87225C9.16453 6.87225 6.87563 9.16166 6.87563 12C6.87563 14.8383 9.16453 17.1277 12.0022 17.1277C14.8399 17.1277 17.1288 14.8383 17.1288 12C17.1288 9.16166 14.8399 6.87225 12.0022 6.87225ZM12.0022 15.3337C10.1684 15.3337 8.66927 13.8387 8.66927 12C8.66927 10.1613 10.164 8.6663 12.0022 8.6663C13.8405 8.6663 15.3352 10.1613 15.3352 12C15.3352 13.8387 13.836 15.3337 12.0022 15.3337ZM18.5343 6.6625C18.5343 7.32746 17.9989 7.85853 17.3385 7.85853C16.6737 7.85853 16.1428 7.32299 16.1428 6.6625C16.1428 6.00201 16.6782 5.46647 17.3385 5.46647C17.9989 5.46647 18.5343 6.00201 18.5343 6.6625ZM21.9297 7.87638C21.8539 6.27424 21.488 4.85507 20.3146 3.68582C19.1456 2.51657 17.7267 2.15062 16.1249 2.07029C14.4741 1.97657 9.52593 1.97657 7.87507 2.07029C6.27775 2.14616 4.8589 2.51211 3.68544 3.68136C2.51199 4.85061 2.15059 6.26978 2.07027 7.87192C1.97658 9.52315 1.97658 14.4724 2.07027 16.1236C2.14612 17.7258 2.51199 19.1449 3.68544 20.3142C4.8589 21.4834 6.27328 21.8494 7.87507 21.9297C9.52593 22.0234 14.4741 22.0234 16.1249 21.9297C17.7267 21.8538 19.1456 21.4879 20.3146 20.3142C21.4835 19.1449 21.8494 17.7258 21.9297 16.1236C22.0234 14.4724 22.0234 9.52761 21.9297 7.87638ZM19.797 17.8953C19.449 18.7701 18.7752 19.4439 17.8963 19.7965C16.58 20.3186 13.4568 20.1981 12.0022 20.1981C10.5477 20.1981 7.41997 20.3142 6.1082 19.7965C5.23369 19.4484 4.55996 18.7745 4.20747 17.8953C3.68544 16.5788 3.80591 13.4549 3.80591 12C3.80591 10.5451 3.68991 7.41671 4.20747 6.10465C4.55549 5.22995 5.22922 4.55606 6.1082 4.2035C7.42443 3.68136 10.5477 3.80185 12.0022 3.80185C13.4568 3.80185 16.5845 3.68582 17.8963 4.2035C18.7708 4.5516 19.4445 5.22548 19.797 6.10465C20.319 7.42118 20.1985 10.5451 20.1985 12C20.1985 13.4549 20.319 16.5833 19.797 17.8953Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Instagram
