'use client'

import cn from 'clsx'
import { FC } from 'react'
import { UserIcon } from '@components/icons'
import { useClientTranslation } from '@app/i18n/client'

interface ILoginButtonContentProps {
  customerName?: string
}

const LoginButtonContent: FC<ILoginButtonContentProps> = ({ customerName }) => {
  const { t } = useClientTranslation('common')

  return (
    <div className="inline-flex h-11 items-center rounded px-2.5 hover:bg-accent-5 lg:h-[42px]">
      <div className={cn('relative', !!customerName ? 'mr-3' : 'mr-2')}>
        <UserIcon />
        {!!customerName && (
          <span className="absolute right-[-6px] top-[-3px] h-1.5 w-1.5 rounded-full bg-success" />
        )}
      </div>
      <div className="hidden text-overline text-xs font-medium uppercase lg:block">
        {!!customerName ? (
          customerName
        ) : (
          <>
            {t('Login')} /<br />
            {t('Register')}
          </>
        )}
      </div>
    </div>
  )
}

export default LoginButtonContent
