'use client'

import { useParams, usePathname, useRouter } from 'next/navigation'
import { FC, useEffect } from 'react'
import urlJoin from 'url-join'
import { staticRoutes } from '@lib/constants/routes'
import { useCustomer } from '@lib/hooks/data/customer/useCustomer'

const redirectKey = 'sign_in_redirect'

export const setRedirect = (redirect: string) => {
  window.sessionStorage.setItem(redirectKey, redirect)
}

export const getRedirect = (): string | null => {
  return window.sessionStorage.getItem(redirectKey)
}

export const clearRedirect = () => {
  return window.sessionStorage.removeItem(redirectKey)
}

export default function withAuth<P>(WrappedComponent: FC<P>): FC<P> | null {
  return function WithAuth(props: P) {
    const router = useRouter()
    const pathname = usePathname()
    const params = useParams()

    const {
      data: customer,
      isLoading: isCustomerLoading,
      refetch: refetchCustomer,
    } = useCustomer()

    useEffect(() => {
      if (!customer) {
        refetchCustomer()
      }
    }, [customer, refetchCustomer])

    useEffect(() => {
      if (!isCustomerLoading && !customer) {
        if (pathname) {
          setRedirect(pathname)
        }

        router.push(
          urlJoin('/', params?.lang as string, staticRoutes.login.url)
        )
      }
    }, [pathname, router, isCustomerLoading, customer, params])

    return <WrappedComponent {...(props as any)} />
  }
}
