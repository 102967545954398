import { Fragment } from 'react'

export default function highlightSearchText(
  phraseName: string,
  searchInputValue: string | undefined
): JSX.Element {
  const regex = new RegExp(searchInputValue ?? '', 'gi')
  const parts = phraseName?.split(regex)

  return (
    <Fragment>
      {parts?.map((part, index) => (
        <Fragment key={index}>
          {part}
          {index !== parts.length - 1 && (
            <strong className="font-bold">{regex.exec(phraseName)}</strong>
          )}
        </Fragment>
      ))}
    </Fragment>
  )
}
