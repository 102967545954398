import { FC } from 'react'
import ProductPrice from '@components/product/ProductPrice'
import Img from '@components/ui/Image'
import Link from '@components/ui/Link'
import { PRODUCT_AUTOCOMPLETE_KEY } from '@lib/constants/lupa'
import { useLupaItemClickEvent } from '@lib/hooks/search/useLupaItemClickEvent'
import { AutocompleteProduct } from '@lib/types/search'
import buildProductUrl from '@lib/utils/product/buildProductUrl'

type Props = {
  product: AutocompleteProduct
  setSearchInputValue: (value: string) => void
  searchQuery: string
}

const AutocompleteProductCard: FC<Props> = ({
  product,
  setSearchInputValue,
  searchQuery,
}) => {
  const { mutate } = useLupaItemClickEvent()

  const handleProductClick = () => {
    setSearchInputValue('')

    mutate({
      queryKey: PRODUCT_AUTOCOMPLETE_KEY,
      searchQuery,
      itemId: product.id,
    })
  }

  return (
    <Link
      href={buildProductUrl(product?.slug)}
      className="flex items-center rounded p-[10px] hover:bg-accent-5"
      onClick={handleProductClick}
    >
      <Img
        width={67}
        height={75}
        src={product?.image}
        alt={product?.name}
        className="h-fit"
      />
      <div className="ml-[20px]">
        {product?.brand && (
          <div className="font-grotesk font-bold">{product?.brand}</div>
        )}
        <div className="text-sm">{product?.name}</div>
        <ProductPrice
          originalPrice={product?.originalPrice}
          price={product?.price}
        />
      </div>
    </Link>
  )
}

export default AutocompleteProductCard
