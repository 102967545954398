'use client'

import cn from 'clsx'
import { FC, useState } from 'react'
import SubHeaderContent from '@components/header/SubHeader/SubHeaderContent'
import Link from '@components/ui/Link'
import { MenuItem } from '@lib/types'

type Props = {
  activeCategory?: MenuItem
}

const SubHeader: FC<Props> = ({ activeCategory }) => {
  const [activeSubCategory, setActiveSubCategory] = useState<MenuItem | null>(
    null
  )

  return (
    <nav
      className={cn(
        'group container hidden justify-between bg-white py-2 transition-[margin] duration-500 lg:flex'
      )}
      onMouseLeave={() => setActiveSubCategory(null)}
    >
      <div className="flex">
        {activeCategory?.children?.map((item) => {
          if (!item.rightMenu) {
            return (
              <Link
                key={item?.id}
                onMouseEnter={() => setActiveSubCategory(item)}
                className="navButton"
                title={item?.label ?? ''}
                href={item.url ?? ''}
              >
                {item?.label}
              </Link>
            )
          }
        })}
        {!!activeSubCategory?.children?.length && (
          <SubHeaderContent activeSubCategory={activeSubCategory} />
        )}
      </div>
      <div className="flex items-center">
        {activeCategory?.children?.map((item) => {
          if (item.rightMenu) {
            return (
              <Link
                title={item?.label ?? ''}
                key={item.id}
                href={item?.url ?? ''}
                className="navButton first:font-bold first:text-red"
              >
                {item.label}
              </Link>
            )
          }
        })}
      </div>
    </nav>
  )
}

export default SubHeader
