'use client'

import * as Popover from '@radix-ui/react-popover'
import { FC } from 'react'
import LoginButtonContent from '@components/common/Navbar/NavbarUser/LoginButtonContent'
import CustomerDropdownMenu from '@components/customer/CustomerDropdownMenu'
import Link from '@components/ui/Link'
import { staticRoutes } from '@lib/constants/routes'
import { customerRoutes } from '@lib/constants/routes/customerRoutes'
import { useCustomer } from '@lib/hooks/data/customer/useCustomer'
import { useLayout } from '@lib/hooks/useLayout'
import withQueryClient from '@app/with-query-client'

const NavbarUser: FC = () => {
  const { data: customer } = useCustomer()

  const { isDesktop } = useLayout()

  if (isDesktop && !!customer) {
    return (
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="focus:outline-0">
            <LoginButtonContent customerName={customer?.firstName || ''} />
          </button>
        </Popover.Trigger>
        <Popover.Content
          align="end"
          sideOffset={2}
          className="bg-white py-2 shadow"
        >
          <CustomerDropdownMenu />
        </Popover.Content>
      </Popover.Root>
    )
  }

  return (
    <Link
      href={!!customer ? customerRoutes.profile.url : staticRoutes.login.url}
    >
      <LoginButtonContent customerName={customer?.firstName || ''} />
    </Link>
  )
}

export default withQueryClient(NavbarUser)
