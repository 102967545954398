import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCart } from '@lib/contexts'
import { clearAuthState } from '@lib/handlers/auth'

export const useLogout = () => {
  const queryClient = useQueryClient()

  const { resetCart } = useCart()

  return useMutation(async () => {
    clearAuthState()
    resetCart()

    await queryClient.refetchQueries(['customer'])

    queryClient.refetchQueries(['wishlist'])
  })
}
