const FacebookMono = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 12.0669C23 5.95335 18.0766 1 12 1C5.92339 1 1 5.95335 1 12.0669C1 17.5906 5.02254 22.1691 10.2812 23V15.2661H7.4869V12.0669H10.2812V9.62864C10.2812 6.85521 11.9224 5.32325 14.436 5.32325C15.6398 5.32325 16.8985 5.53923 16.8985 5.53923V8.26134H15.5111C14.145 8.26134 13.7188 9.11456 13.7188 9.98965V12.0669H16.7695L16.2816 15.2661H13.7188V23C18.9775 22.1691 23 17.5906 23 12.0669Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FacebookMono
