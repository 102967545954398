export const getImageSrcFromPath = (path?: string | null) => {
  if (!path) {
    return undefined
  }

  const backendUrl = new URL('', process.env.ADEOWEB_SYLIUS_API_URL)
  const absoluteUrl = new URL(path, backendUrl.origin)

  return absoluteUrl.href
}
