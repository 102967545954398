import debounce from 'lodash.debounce'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { lupaDefaultParameters } from '@lib/constants/lupa'
import { useUI } from '@lib/contexts'
import { useCustomer } from '@lib/hooks/data/customer/useCustomer'
import { AutocompleteResults } from '@lib/types/search'
import buildSearchUrl from '@lib/utils/url/buildSearchUrl'
import { onStart } from '@app/router-events'
import { useLayout } from './useLayout'

export const useAutocompleteSearch = () => {
  const { isDesktop } = useLayout()
  const [inputValue, setInputValue] = useState<string>('')
  const [searchAutoCompleteResults, setSearchAutoCompleteResults] =
    useState<AutocompleteResults>()
  const { closeSearch, displayModal } = useUI()
  const ref = useRef<HTMLInputElement>(null)
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const [startingPath] = useState(`${pathname}?${searchParams}`)

  const { data: customer } = useCustomer()

  const handleClose = useCallback(() => {
    closeSearch()
    setInputValue('')
  }, [closeSearch, setInputValue])

  const collapseSearchDropdown = useCallback(
    ({ target }: Event) => {
      if (
        ref.current &&
        !ref.current.contains(target as Node) &&
        !displayModal &&
        inputValue.length
      ) {
        handleClose()
      }
    },
    [handleClose, displayModal, inputValue]
  )

  useEffect(() => {
    if (startingPath !== `${pathname}?${searchParams}`) {
      closeSearch()
    }
  }, [pathname, searchParams, closeSearch, startingPath])

  useEffect(() => {
    if (isDesktop) {
      window?.addEventListener('mousedown', collapseSearchDropdown)

      return () => {
        window?.removeEventListener('mousedown', collapseSearchDropdown)
      }
    }
  }, [isDesktop, collapseSearchDropdown])

  const handleSearch = (e: SyntheticEvent) => {
    e.preventDefault()

    if (inputValue !== '' && inputValue.length >= 1) {
      onStart()
      router.push(buildSearchUrl('/', inputValue))

      setInputValue('')
    }
  }

  const fetchAutocomplete = async (query: string) => {
    if (query.length > 1) {
      try {
        const getAutocompleteResults = async () => {
          try {
            const response = await fetch('/api/lupa/autocomplete', {
              ...lupaDefaultParameters,
              body: JSON.stringify({
                query,
                userId: customer?.id?.toString() || null,
              }),
            })

            return response.json()
          } catch (error) {
            throw error
          }
        }

        const results = await getAutocompleteResults()

        setSearchAutoCompleteResults(results)
      } catch (error: unknown) {
        //
      }
    }
  }

  const debouncedFetch = useMemo(() => debounce(fetchAutocomplete, 300), [])

  const handleInputChange = async (inputText: string) => {
    setInputValue(inputText)
    debouncedFetch(inputText)
  }

  const phrases = searchAutoCompleteResults?.phrases
  const products = searchAutoCompleteResults?.products
  const totalProducts = searchAutoCompleteResults?.totalProducts

  return {
    handleSearch,
    handleInputChange,
    phrases,
    products,
    inputValue,
    setInputValue,
    totalProducts,
    handleClose,
    ref,
  }
}
