import {
  HeartIcon,
  HouseIcon,
  PackageIcon,
  RefundArrowsIcon,
  UserIcon,
} from '@components/icons'
import { customerRoutes } from '@lib/constants/routes'

type TMenuCustomerItem = {
  title: string
  href: string
  icon: JSX.Element
}

export const customerMenu: TMenuCustomerItem[] = [
  {
    title: customerRoutes.profile.name,
    href: customerRoutes.profile.url,
    icon: <UserIcon />,
  },
  {
    title: customerRoutes.orders.name,
    href: customerRoutes.orders.url,
    icon: <PackageIcon />,
  },
  {
    title: customerRoutes.returns.name,
    href: customerRoutes.returns.url,
    icon: <RefundArrowsIcon />,
  },
  {
    title: customerRoutes.addresses.name,
    href: customerRoutes.addresses.url,
    icon: <HouseIcon />,
  },
  {
    title: customerRoutes.wishlist.name,
    href: customerRoutes.wishlist.url,
    icon: <HeartIcon />,
  },
]
