import { useRouter } from 'next/navigation'
import { useMemo } from 'react'
import { HeartIcon } from '@components/icons'
import { Button } from '@components/ui'
import { customerRoutes } from '@lib/constants/routes'
import { EModalView } from '@lib/constants/ui'
import { useUI } from '@lib/contexts'
import { useWishlist } from '@lib/hooks/customer/useWishlist'
import { useCustomer } from '@lib/hooks/data/customer/useCustomer'
import withQueryClient from '@app/with-query-client'

const WishlistButton = () => {
  const { data: customer } = useCustomer()
  const { data: wishlist } = useWishlist()

  const { push } = useRouter()
  const { openModal, setModalView } = useUI()

  const isEmpty = useMemo(() => !wishlist?.items?.length, [wishlist])

  const onWishlistClick = () => {
    if (!customer) {
      setModalView(EModalView.Login)
      return openModal()
    }

    return push(customerRoutes.wishlist.url)
  }

  return (
    <Button width={42} height={42} variant="ghost" onClick={onWishlistClick}>
      <HeartIcon className={!isEmpty ? 'fill-black' : ''} />
    </Button>
  )
}

export default withQueryClient(WishlistButton)
