'use client'

import * as Popover from '@radix-ui/react-popover'
import cn from 'clsx'
import { useParams, usePathname, useRouter } from 'next/navigation'
import { FC } from 'react'
import { LogoutIcon } from '@components/icons'
import Link from '@components/ui/Link'
import { customerMenu } from '@lib/constants/customerMenu'
import { useLogout } from '@lib/hooks/data/auth/useLogout'
import { useClientTranslation } from '@app/i18n/client'
import { clearRedirect } from '@app/with-auth'
import withQueryClient from '@app/with-query-client'

const CustomerDropdownMenu: FC = () => {
  const router = useRouter()
  const { mutateAsync: logout } = useLogout()
  const pathname = usePathname()
  const params = useParams()

  const { t } = useClientTranslation('common')

  const handleLogout = async () => {
    try {
      await logout()

      clearRedirect()

      router.push(`/${params?.lang ?? ''}`)
    } catch {
      // TODO: Display error to the user
    }
  }

  const sharedLinkClasses =
    'flex items-center rounded py-2 pl-3 pr-16 hover:bg-accent-5 hover:outline-0'

  return (
    <div className="grid gap-3 text-sm font-medium text-secondary">
      {customerMenu.map((item, i) => (
        <Popover.Close key={`${item.href}-${i}`} asChild>
          <Link
            className={cn(
              sharedLinkClasses,
              pathname === item.href && 'bg-white'
            )}
            href={item.href}
          >
            {item.icon}
            <span className="ml-3">{t(item.title)}</span>
          </Link>
        </Popover.Close>
      ))}
      <hr />
      <button onClick={handleLogout} className={sharedLinkClasses}>
        <LogoutIcon />
        <span className="ml-3">{t('Logout')}</span>
      </button>
    </div>
  )
}

export default withQueryClient(CustomerDropdownMenu)
