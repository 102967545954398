'use client'

import clsx from 'clsx'
import { FC, ReactNode } from 'react'

interface Props {
  children?: ReactNode
  isCheckout?: boolean
}

const NavbarRoot: FC<Props> = ({ children, isCheckout }) => {
  return (
    <div
      className={clsx(
        'fixed top-[36px] z-40 w-full bg-primary transition-all duration-150 lg:border-b',
        isCheckout && '!top-0'
      )}
    >
      {children}
    </div>
  )
}

export default NavbarRoot
