import { useMutation } from '@tanstack/react-query'
import { useCustomer } from '@lib/hooks/data/customer/useCustomer'

interface LupaSuggestionClickEventBody {
  queryKey: string | undefined
  searchQuery?: string | string[]
  suggestion?: string
}

export const useLupaSuggestionClickEvent = () => {
  const { data: customer } = useCustomer()

  return useMutation(
    async ({
      queryKey,
      searchQuery,
      suggestion,
    }: LupaSuggestionClickEventBody) => {
      if (!queryKey || !suggestion) {
        return
      }

      await fetch('/api/lupa/events', {
        method: 'POST',
        body: JSON.stringify({
          queryKey,
          name: 'suggestionClick',
          searchQuery: Array.isArray(searchQuery)
            ? searchQuery?.[0] || ''
            : searchQuery || '',
          itemId: suggestion,
          userId: customer?.id?.toString() || null,
        }),
      })
    }
  )
}
