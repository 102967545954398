'use client'

import { FC } from 'react'
import { Collapse } from '@components/ui'
import Link from '@components/ui/Link'
import { useLayout } from '@lib/hooks/useLayout'
import { Menu } from '@lib/types'

interface Props {
  menuData: Menu
}

const FooterLinks: FC<Props> = ({ menuData }) => {
  const { isDesktop } = useLayout()

  if (isDesktop) {
    return (
      <div className="flex justify-between gap-6">
        {menuData?.children?.map((group) => (
          <div key={group.id} className="w-[170px] text-sm">
            <div className="mb-6 font-bold uppercase">{group.label}</div>
            <div className="flex flex-col gap-4">
              {group?.children?.map((item) => (
                <Link
                  key={item.id}
                  href={item.url ?? '/'}
                  className="hover:text-accent-2"
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <>
      {menuData?.children?.map((group) => (
        <Collapse
          key={group.id}
          classes={{
            root: 'bg-transparent',
            title: 'font-medium',
          }}
          title={group.label ?? ''}
        >
          <div className="flex flex-col">
            {group?.children?.map((item) => (
              <Link
                key={item.id}
                href={item.url ?? '/'}
                className="block py-3 text-accent-3 hover:text-accent-5"
              >
                {item.label}
              </Link>
            ))}
          </div>
        </Collapse>
      ))}
    </>
  )
}

export default FooterLinks
